import React from "react";
import HeadComponent from "../components/headerComponent";
import FooterComponent from "../components/footerComponent";
import BodyComponentStore from "../components/bodyComponentStore";

const OwnAStoreView = () => {
    return (
        <>
            <HeadComponent/>
            <BodyComponentStore/>
            <FooterComponent/>
        </>
    )
}

export default OwnAStoreView;
