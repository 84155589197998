import styles from "../css/homeView.module.scss";
import logoHeader from "../assets/images/Logo 1.png";
import {Link} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import ReactFlagsSelect from "react-flags-select";
import 'react-flags-select/'


const HeaderComponent = ({t}) => {

    const {i18n} = useTranslation();
    const [selected, setSelected] = useState();
    
    const handleLanguageChange = (countryCode) => {
        const lang = countryCode.toLowerCase();
        setSelected(countryCode);
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
    };
    useEffect(() => {
        const storedLanguage = localStorage.getItem('i18nextLng') || 'gb';
        handleLanguageChange(storedLanguage.toUpperCase());
    }, []);



    return (
        <>
            <header>
                <div className={`row ${styles.respHeader}`}
                     style={{margin: 'auto', position: "fixed", zIndex: 1, background: 'white', width: '100%'}}>
                    
                    
                    <div className="row">
                        <div className={`col-5 ${styles.headerDivImg}`} ><a href="/#home"><img src={logoHeader}
                                                                                              className={styles.imgClass}></img></a>
                        </div>
                        <div className={`col-7 ${styles.topnav}`} >

                            <a className={styles.navLInks} href="/#home">{t('headers.home')}</a>
                            <a className={styles.navLInks} href="/#mission">{t('headers.mission')}</a>
                            <a className={styles.navLInks} href="/#service">{t('headers.service')}</a>
                            <a className={styles.navLInks} href="/#whyus">{t('headers.whyUs')}</a>
                            <a className={styles.navLInks} href="/#goals">{t('headers.goals')}</a>
                            <a className={styles.navLInks} href="/#contact">{t('headers.contact')}</a>
                            <Link to="/ownAStore"
                                  className={`btn  ${styles.navLInks} ${styles.ownStoreBtn}`}>{t('headers.ownAStore')}</Link>
                        </div>
                        <div className={`col-1 ${styles.lngPicker}`}>
                            <ReactFlagsSelect
                                fullWidth={false}
                                countries={["GB", "NL"]}
                                customLabels={{GB: "EN", NL: "NL" }}
                                placeholder="Select Language"
                                selected={selected}
                                onSelect={handleLanguageChange}
                                selectedSize={16}
                     
                                className={`${styles.test}`}
                                selectButtonClassName={`${styles.selectLanguage}`}
                                alignOptionsToRight={true}
                                
                            />
                        </div>
                    </div>
                   
                </div>
            </header>
        </>
    )
}

export default withTranslation()(HeaderComponent)
