
import ownAStoreLogo from "../assets/images/Graphic Own a store.png";
import ownAStoreLogo1 from "../assets/images/Graphic Own a store 1.png";
import {Form, FormControl, InputGroup} from "react-bootstrap";
import styles from "../css/homeView.module.scss";
import OwnAStoreForm from "./ownAStoreComponent";
import {useTranslation} from "react-i18next";
import React , {useState} from "react";
const BodyComponentStore = () => {
    
    const { t } = useTranslation();
    const { i18n } = useTranslation();
  
    
    return (
        <>

            <div>
                <div style={{paddingTop: '6em', paddingLeft: '12em', backgroundColor: '#f6f6ff'}}
                     className={`row ${styles.joinUsRespRow}`}>
                    <div className={`col-6 ${styles.paddingClass} ${styles.joinUsDivResp}`}>
                        <span className={styles.span1}>{t('ownAStore.ownAStore?')}</span><br></br>
                        <span className={styles.span2}>{t('ownAStore.sellYourProducts')}</span>
                        <p className={styles.paragraphText}>{t('ownAStore.text1')}<br></br>
                            {t('ownAStore.text2')} <span>&#128151;</span> </p>
                        <img className={styles.joinUsImg} src={ownAStoreLogo}></img>
                    </div>
                    <div style={{paddingRight: '13em'}}
                         className={`col-6 ${styles.joinUsDivResp} ${styles.joinUsDivResp2}`}>
                        <h2 className={styles.joinUs}>{t('ownAStore.joinUs')}</h2>
                        <OwnAStoreForm></OwnAStoreForm>

                        <img className={styles.joinUsImg2} src={ownAStoreLogo1}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BodyComponentStore;
