import React, { useState } from 'react';
import logoImage from '../assets/images/Group 1199.png';
import shape from '../assets/images/Shape.png';
import '../css/drawer.scss';
import {useTranslation} from "react-i18next";

const DrawerComponent = () => {
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCheckboxChange = () => {
        setIsChecked((prevState) => !prevState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        // You can use the 'name' and 'email' states for the form data
    };

    return (
        <div>
            <img src={logoImage} alt="Logo" className="imgAtTop" />
            <div style={{ marginTop: '12em', width: '100%', paddingLeft:'2em',paddingRight:'2em' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={shape} alt="shape" style={{ marginRight: '10px', height:'69px' }} />
                    <h2  className="hello-there">{t('drawer.helloThere')}</h2>
                </div>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', marginTop:'3em' }}>
                    <div style={{marginBottom:'1em'}}>
                        <span className='span1text'>{t('drawer.signup')}</span>
                        <input
                            type="text"
                            id="name"
                            placeholder={t('drawer.name')}
                            value={name}
                            onChange={handleNameChange}
                            required
                            style={{marginTop:'1em'}}
                            className="inputForm"

                        />
                    </div>
                    <div>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            placeholder={t('drawer.email')}
                            onChange={handleEmailChange}
                            required
                            className="inputForm"
                        />
                    </div>
                    <button type="submit" className='signupButton'>{t('drawer.signUP')}</button>
                    <div style={{marginTop:'5em'}}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            required
                        />
                        <span className="privacy-policy"> {t('drawer.accept1')} <span className="privacy-policy-bold">{t('drawer.accept2')}</span></span>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DrawerComponent;
