import {Form, FormControl, InputGroup} from "react-bootstrap";
import styles from "../css/homeView.module.scss";
import {useTranslation} from "react-i18next";
import React , {useState} from "react";
import { withTranslation } from 'react-i18next';
let objToSend = {
    shopName: '', shopType: '', contactPerson: '', email: '', website: '', comment: '',
}



function makeObjToSend(name, value) {

    if (name === 'shopName') {
        objToSend.shopName = value
    } else if (name === 'shopType') {
        objToSend.shopType = value
    } else if (name === 'contactPerson') {
        objToSend.contactPerson = value;
    } else if (name === 'email') {
        objToSend.email = value
    } else if (name === 'website') {
        objToSend.website = value
    } else if (name === 'comment') {
        objToSend.comment = value
    }
}

class OwnAStoreForm extends React.Component {
    


    constructor(props) {
        super(props);
        makeObjToSend('comment',this.props.t('ownAStore.receiveMoreInfo'))
        this.state = {
            value: this.props.t('ownAStore.receiveMoreInfo'), // Assuming you're passing 't' as a prop
            changed: false
          };
    }

    componentDidUpdate() {
        if ((this.state.value !== this.props.t('ownAStore.receiveMoreInfo')) && !this.state.changed) {
          this.setState({
            value: this.props.t('ownAStore.receiveMoreInfo')
          });
          makeObjToSend('comment',this.props.t('ownAStore.receiveMoreInfo'))
   
        }
      }

    mailCommentChanged(commentValue){
        makeObjToSend('comment', commentValue);
        this.setState({
            value:commentValue,
            changed: true
        })
    }

 
    render() {
        const { t } = this.props;
        return (<Form>
            <div className="row">
                <div className={`col-6 ${styles.formInput}`}>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={t('ownAStore.shopName')}
                            aria-label="shopName"
                            onChange={event => makeObjToSend('shopName', event.target.value)}
                            aria-describedby="basic-addon1"
                            className={styles.inputRadius}
                            style={{borderRadius: '25px'}}
                        />
                    </InputGroup>
                </div>
                <div className={`col-6 ${styles.formInput}`}>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={t('ownAStore.shopType')}
                            aria-label="shopType"
                            onChange={event => makeObjToSend('shopType', event.target.value)}
                            aria-describedby="basic-addon1"
                            className={styles.inputRadius}
                            style={{borderRadius: '25px'}}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={`col-12 ${styles.formInput}`}>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder={t('ownAStore.contactPerson')}
                        aria-label="contactPerson"
                        onChange={event => makeObjToSend('contactPerson', event.target.value)}
                        aria-describedby="basic-addon1"
                        className={styles.inputRadius}
                        style={{borderRadius: '25px'}}
                    />
                </InputGroup>
            </div>
            <div className={`col-12 ${styles.formInput}`}>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder={t('ownAStore.emailAddress')}
                        aria-label="Email"
                        onChange={event => makeObjToSend('email', event.target.value)}
                        aria-describedby="basic-addon1"
                        className={styles.inputRadius}
                        style={{borderRadius: '25px'}}
                    />
                </InputGroup>
            </div>
            <div className={`col-12 ${styles.formInput}`}>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder={t('ownAStore.webSite')}
                        aria-label="website"
                        onChange={event => makeObjToSend('website', event.target.value)}
                        aria-describedby="basic-addon1"
                        className={styles.inputRadius}
                      
                        style={{borderRadius: '25px'}}
                    />
                </InputGroup>
            </div>
            <div className={`col-12 ${styles.formInput}`}>
                <InputGroup>
                    <FormControl style={{height: '137px', marginBottom: '1em', borderRadius: '25px'}}
                                 as="textarea" aria-label="With textarea"
                                 onChange={event => this.mailCommentChanged(event.target.value)}
                                 value={this.state.value}
                                 className={styles.inputRadius}/>
                </InputGroup>
            </div>
            <Form.Check
                type='checkbox'
                label={t('ownAStore.iAcceptPrivacyPolicy')}
                style={{marginBottom: '1em'}}
            />

            <button onClick={mailto} className={`col-12 btn ${styles.submitBtn}`} style={{
                borderRadius: '25px',
                color: 'white',
                backgroundColor: '#1A3957',
                letterSpacing: '-0.16px'
            }}>{t('ownAStore.submit')}
            </button>
        </Form>);
    }
}

function mailto(email, subject, body) {
    email = 'info@nicetogift.com'
    subject = "OWN A STORE MORE INFORMATION"
    body = "SHOP_NAME: " + `${objToSend.shopName}` + "\n" + " SHOP_TYPE: " + ` ${objToSend.shopType}`  + "\n" + " CONTACT_PERSON: " + ` ${objToSend.contactPerson}` +
        "\n" + " EMAIL_ADDRESS: " + ` ${objToSend.email}`  + "\n" + " WEB_SITE: " + ` ${objToSend.website}` + "\n" + " COMMENT: " + ` ${objToSend.comment}`

    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    window.location.href = `mailto:${email}${params}`

}


export default withTranslation()(OwnAStoreForm);
