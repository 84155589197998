import React, {useState} from "react";
import {Form, FormControl, InputGroup} from "react-bootstrap";
import styles from "../css/homeView.module.scss";
import { withTranslation } from 'react-i18next';
let objToSend = {
    firstName: '',
    subject: '',
    email: '',
    comment: '',
}

function makeObjToSend(name, value) {

    if (name === 'firstName') {
        objToSend.firstName = value
    } else if (name === 'subject') {
        objToSend.subject = value;
    } else if (name === 'email') {
        objToSend.email = value
    } else if (name === 'comment') {
        objToSend.comment = value
    }
}

class NameForm extends React.Component {

    


    constructor(props) {
        super(props);
        
    }


    render() {
        const { t } = this.props;
        return (
            <Form>
                <div className="row">
                    <div className={`col-6 ${styles.formInput} ${styles.bellowSixty}`}>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={t('form.name')}
                                onChange={event => makeObjToSend('firstName', event.target.value)}
                                value={this.firstName}
                                aria-label="Name"
                                aria-describedby="basic-addon1"
                                className={styles.inputRadius}
                                style={{borderRadius: '25px'}}
                            />
                        </InputGroup>
                    </div>
                    <div className={`col-6 ${styles.formInput}  ${styles.bellowSixty}`}>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={t('form.subject')}
                                aria-label="Subject"
                                onChange={event => makeObjToSend('subject', event.target.value)}
                                value={this.subject}
                                aria-describedby="basic-addon1"
                                className={styles.inputRadius}
                                style={{borderRadius: '25px'}}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div className={`col-12 ${styles.formInput}`}>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={t('form.emailAddress')}
                            onChange={event => makeObjToSend('email', event.target.value)}
                            value={this.email}
                            aria-label="Email"
                            aria-describedby="basic-addon1"
                            className={styles.inputRadius}
                            style={{borderRadius: '25px'}}
                        />
                    </InputGroup>
                </div>
                <div className={`col-12 ${styles.formInput}`}>
                    <InputGroup>
                        <FormControl style={{height: '137px', marginBottom: '1em', borderRadius: '25px'}}
                                     as="textarea" aria-label="With textarea"
                                     onChange={event => makeObjToSend('comment', event.target.value)}
                                     value={this.comment}
                                     placeholder={t('form.yourMessageHere')}
                                     className={styles.inputRadius}/>
                    </InputGroup>
                </div>
                <Form.Check
                    type='checkbox'
                    label={t('form.iAcceptPrivacyPolicy')}
                    style={{marginBottom: '1em'}}
                />
                <button onClick={mailto} className={`col-12 btn ${styles.submitBtn}`} style={{
                    borderRadius: '25px',
                    color: 'white',
                    backgroundColor: '#1A3957',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    letterSpacing: '-0.16px'
                }}>
                    {t('form.submit')}
                </button>
            </Form>
        );
    }
}

function mailto(email, subject, body) {
    email = 'tessamei@hotmail.com'
    subject = objToSend.subject
    body = "NAME: " + `${objToSend.firstName}` + "\n" + " COMMENT: " + ` ${objToSend.comment}`
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    window.location.href = `mailto:${email}${params}`

}


export default withTranslation()(NameForm);
