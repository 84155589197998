import './App.scss';
import HomeView from "./screens/homeView";
import OwnAStore from "./screens/ownAStoreView";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config.js'; // Your i18n configuration file


function App() {
    return (
        <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<HomeView/>}/>
                <Route exact path="/ownAStore" element={<OwnAStore/>}/>
            </Routes>
        </BrowserRouter>
        </I18nextProvider>

    );
}

export default App;
