import styles from "../css/homeView.module.scss";
import React, {useState, useEffect, useRef} from "react";
import {Carousel, Form, FormControl, InputGroup} from "react-bootstrap";
import slider1 from "../assets/images/Graphic Slider 1.png";
import slider2 from "../assets/images/Graphic Slider 2.png";
import slider3 from "../assets/images/test.png";
import slider4 from "../assets/images/CrowdFunding.png";
import graphic1 from "../assets/images/Graphic 1.png";
import problemIcon from "../assets/images/Problem Icon.png";
import solutionIcon from "../assets/images/Solution Icon.png";
import graphic2 from "../assets/images/Graphic 2.png";
import graphic3 from "../assets/images/Graphic 3.png";
import {BsFillCheckCircleFill, BsFillRecordCircleFill} from "react-icons/bs";
import graphic4 from "../assets/images/Graphic 4.png";
import goal1 from "../assets/images/Goal 1.png";
import goal2 from "../assets/images/Goal 2.png";
import goal3 from "../assets/images/Goal 3.png";
import sdg2 from "../assets/images/SDG 2.png";
import sdg1 from "../assets/images/SDG 1.png";
import sdgLogo from "../assets/images/SDG logo.png";
import graphic5 from "../assets/images/Graphic 5.png";
import NameForm from "./formComponent";
import ResponsiveCarousel from "./carousel";
import {useTranslation} from "react-i18next";
import portret from "../assets/images/Slika + kuglica.svg";
import formLogo from "../assets/images/Group 1178 1.png";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DrawerComponent from "./drawerComponent";

const BodyComponentMain = () => {


    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        console.log('toggleDrawer called');
        setIsOpen(!isOpen)
    }


    return (
        <>
            <div className={styles.bodyClass}>
                <div id="home" className={styles.paddingClass}>
                    <Carousel variant="dark" indicators={false} interval={3000}>
                        <Carousel.Item>
                            <div className={`row ${styles.rowHeight}`} style={{paddingLeft: '5em'}}>
                                <div className={`col-md-6 order-md-1 ${styles.sliderTextDiv}`}>
                                    <div className={styles.carouselCaption}>
                                        <h3><span className={styles.spantext1}>{t('carousel.slider1.text1')}</span>
                                            <p className={styles.spantext2} >{t('carousel.slider1.text2')}</p></h3>
                                        <p className={styles.spantext3}>{t('carousel.slider1.text4')}</p>
                                        <p className={styles.spantext3}
                                           style={{marginTop: '-18px'}}> {t('carousel.slider1.text5')}</p>
                                        <p><a className={`btn ${styles.learnMoreBtn}`}
                                              href="#howDoesItWork">{t('carousel.learnMore')}</a>
                                            <button className={`btn ${styles.stayUpdatedBtn}`}
                                                    onClick={toggleDrawer}>{t('carousel.slider4.stayUpdated')}</button>
                                        </p>
                                    </div>
                                </div>
                                <div className={`col-md-6 order-md-1 ${styles.sliderImgDiv}`}>
                                    <img
                                        className={`d-block ${styles.sliderImg} ${styles.slider1Dimension}`}
                                        src={slider1}
                                        alt="First slide"
                                    />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className={`row ${styles.rowHeight}`} style={{paddingLeft: '5em'}}>
                                <div className={`col-md-6 order-md-1 ${styles.sliderTextDiv}`}>
                                    <div className={styles.carouselCaption}>
                                        <h3><span className={styles.spantext1}>{t('carousel.slider2.text1')}</span>
                                            <p className={styles.spantext2}>{t('carousel.slider2.text12')}</p></h3>
                                        <p className={styles.spantext3}>
                                            {t('carousel.slider2.text2')}
                                        </p>
                                        <p className={styles.spantext3} style={{marginTop: "-18px"}}>
                                            {t('carousel.slider2.text3')}
                                        </p>
                                        <a className={`btn ${styles.learnMoreBtn}`} href="#howDoesItWork">
                                            {t('carousel.learnMore')}
                                        </a>
                                        <button className={`btn ${styles.stayUpdatedBtn}`}
                                                onClick={toggleDrawer}>{t('carousel.slider4.stayUpdated')}</button>
                                    </div>
                                </div>
                                <div className={`col-md-6 order-md-1 ${styles.sliderImgDiv}`}>
                                    <img
                                        className={`d-block ${styles.sliderImg} ${styles.slider1Dimension}`}
                                        src={slider2}
                                        alt="Second slide"
                                    />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className={`row ${styles.rowHeight}`} style={{paddingLeft: '5em'}}>
                                <div className={`col-md-6 order-md-1 ${styles.sliderTextDiv}`}>
                                    <div className={styles.carouselCaption}>
                                        <h3><span className={styles.spantext1}>{t('carousel.slider3.text1')}</span>
                                            <p className={styles.spantext2}>{t('carousel.slider3.text2')}</p></h3>
                                        <p className={styles.spantext3}>{t('carousel.slider3.text3')}</p>
                                        <p className={styles.spantext3}
                                           style={{marginTop: '-18px'}}>{t('carousel.slider3.text4')}</p>
                                        <p><a className={`btn ${styles.learnMoreBtn}`}
                                              href="#howDoesItWork">{t('carousel.learnMore')}</a>
                                            <button className={`btn ${styles.stayUpdatedBtn}`}
                                                  onClick={toggleDrawer}>{t('carousel.slider4.stayUpdated')}</button>
                                        </p>
                                    </div>
                                </div>
                                <div className={`col-md-6 order-md-1 ${styles.sliderImgDiv}`}>
                                    <img
                                        className={`d-block  ${styles.sliderImg} ${styles.slider1Dimension}`}
                                        src={slider3}
                                        alt="Third slide"
                                    />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className={`row ${styles.rowHeight}`} style={{paddingLeft: '5em'}}>
                                <div className={`col-md-6 order-md-1 ${styles.sliderTextDiv}`}>
                                    <div className={styles.carouselCaption}>
                                        <h3>
                                            <span className={styles.spantext1}>{t('carousel.slider4.text1')}</span> 
                                            <p className={styles.spantext2}>{t('carousel.slider4.text2')}</p>
                                            <span className={styles.spantext1} style={{position: 'relative', top:'-20px'}}> {t('carousel.slider4.text3')}</span> 
                                        </h3>
                                        <p className={styles.spantext3}>{t('carousel.slider4.text4')}</p>
                                        <p className={styles.spantext3}
                                           style={{marginTop: '-18px'}}>{t('carousel.slider4.text5')}</p>
                                        <p><a className={`btn ${styles.learnMoreBtn}`}
                                              href="https://crowdaboutnow.nl/campagnes/nice-to-gift" target="_blank">{t('carousel.slider4.seeTheCampaign')}</a>
                                            <button className={`btn ${styles.stayUpdatedBtn}`}
                                                    onClick={toggleDrawer}>{t('carousel.slider4.stayUpdated')}</button>
                                        </p>
                                    </div>
                                </div>
                                <div className={`col-md-6 order-md-1 ${styles.sliderImgDiv}`}>
                                    <img
                                        className={`d-block  ${styles.sliderImg} ${styles.slider1Dimension}`}
                                        src={slider4}
                                        alt="Fourth slide"
                                    />
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    <Drawer open={isOpen} onClose={toggleDrawer} direction='right' size={425}>
                        <DrawerComponent/>
                    </Drawer>
                </div>

                <div className={`row ${styles.missionClass}`} id="mission">
                    <div className={`col-6 ${styles.missionResImgDiv}`}>
                        <img src={graphic1} className={`${styles.missionRespImg} ${styles.secondAImg}`} alt=""></img>
                    </div>
                    <div className={`col-6 ${styles.secondDiv}`} style={{width: 'max-content'}}>
                        <h2 className={styles.missionHeading}>{t('body.mission.ourMission')}</h2>
                        <br></br><br></br>
                        <span className={styles.missionSpan1}>{t('body.mission.text1')}<br></br>
                            {t('body.mission.text12')}<br></br>
                            {t('body.mission.text13')}</span>
                        <br></br><br></br><br></br>
                        <span className={styles.missionSpan2normal}><span
                            className={styles.missionSpan2Bold}>{t('body.mission.text2')}</span> {t('body.mission.text22')}
                            </span><br/>
                        <span>{t('body.mission.text23')}</span>
                        <br></br> <br/><br></br>
                        <span className={styles.missionSpan2normal}>{t('body.mission.text3')}<span
                            className={styles.missionSpan2Bold}>{t('body.mission.text32')}</span>{t('body.mission.text33')}</span>
                    </div>
                </div>

                <div className="row" id="service">
                    <div className="col-12">
                        <h2 className={`${styles.serviceHeader} ${styles.serviceBellowSixty}`}
                            style={{marginTop: '1em'}}>{t('body.service.theService')}
                        </h2>
                    </div>
                    <div className={`col-6 ${styles.serviceDivBellowSixty}`} style={{paddingLeft: '9em'}}>

                        <div className={styles.problemCard}>
                            <img src={problemIcon} className={styles.problemIcon}></img>
                            <h3 className={styles.serviceHeadText}>{t('body.service.problem.problem')}</h3>
                            <p className={styles.serviceFirstSlide}>{t('body.service.problem.text1')} <span
                                className={styles.serviceFirstSlideBold}> {t('body.service.problem.text12')}</span>
                            </p>
                            <p className={styles.serviceFirstSlide}>{t('body.service.problem.text2')}

                            </p>
                            <p className={styles.serviceFirstSlide}>{t('body.service.problem.text3')}
                            </p>
                        </div>
                        <div className={styles.solutionCard}>
                            <img src={solutionIcon} className={styles.solutionIcon}></img>
                            <h3 className={styles.serviceHeadText}>{t('body.service.solution.solution')}</h3>
                            <p className={styles.serviceFirstSlide}>{t('body.service.solution.text1')}</p>
                            <p className={styles.serviceFirstSlide}>{t('body.service.solution.text2')}</p>
                            <p className={styles.serviceFirstSlide}>{t('body.service.solution.text3')}</p>
                        </div>
                    </div>
                    <div className={`col-6 ${styles.missionSecondSlide} ${styles.someDiv1}`}>
                        <img src={graphic2} alt="" className={`${styles.serviceImg2}`}></img>
                    </div>
                </div>
                <div className={styles.serviceCard}>
                    <div className="row" id="howDoesItWork">
                        <div className={`col-6 ${styles.serviceDiv1}`} style={{paddingLeft: '9em'}}>
                            <h2 className={styles.serviceHeading}>{t('body.howDoesItWork.howDoesItWork')}</h2>
                            <img src={graphic3} alt="" className={styles.serviceImg}></img>
                        </div>
                        <div className={`col-6 ${styles.innerContent} ${styles.serviceDiv1}`}>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/> <span
                            className={styles.serviceDotTextBold}
                            style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text1')}</span><br></br><br></br>
                            <p className={styles.border}></p>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/> <span
                            className={styles.serviceDotTextNormal}
                            style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text2')}</span><br></br><br></br>
                            <p className={styles.border}></p>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/> <span
                            className={styles.serviceDotTextBold}
                            style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text3')}</span><br></br><br></br>
                            <p className={styles.border}></p>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/> <span
                            className={styles.serviceDotTextBold}
                            style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text4')}</span><br></br><br></br>
                            <p className={styles.border}></p>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/> <span
                            className={styles.serviceDotTextNormal}
                            style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text5')}</span><br></br><br></br>
                            <p className={styles.border}></p>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/> <span
                            className={styles.serviceDotTextNormal}
                            style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text6')}</span><br></br><br></br>
                            <p className={styles.border}></p>
                            <BsFillRecordCircleFill color="#9D98DF" fontSize="15px"/>
                            <span
                                className={styles.serviceDotTextNormal}
                                style={{marginLeft: '2em'}}>{t('body.howDoesItWork.text7')}</span>
                            <span className={styles.serviceDotTextBold}>{t('body.howDoesItWork.text71')}</span>
                            <span className={styles.serviceDotTextNormal}>{t('body.howDoesItWork.text72')}</span>
                            <br></br><br></br>
                        </div>
                    </div>
                </div>

                <div className="row" id="whyus">
                    <div className="col-12">
                        <h2 style={{float: 'right', marginRight: '5em'}}
                            className={`${styles.serviceHeading} ${styles.whyUsHeding}`}>{t('body.whyUs.whyNiceToGift')}</h2>
                    </div>
                    <div className={`col-6 ${styles.whyUsDivImg}`} style={{paddingLeft: '9em'}}>
                        <img src={graphic4} alt="" className={styles.whyUsImg}></img>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className={`col-6 ${styles.textDiv1}`}>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text1')}</div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text2')}
                                </div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text3')}
                                </div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text4')}</div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text5')}</div>
                            </div>
                            <div className={`col-6 ${styles.textDiv2}`}>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text6')}</div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text7')}
                                </div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text8')}
                                </div>
                                <BsFillCheckCircleFill
                                    style={{color: '#9D98DF', position: 'relative', top: '41px', left: '-10px'}}/>
                                <div className={styles.whyDiv}>{t('body.whyUs.text9')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.goalsClass}>
                    <div style={{paddingLeft: '9em'}} className={`row ${styles.golasResp}`} id="goals">
                        <h2 className={styles.serviceHeading}>{t('body.goals.goals')}</h2>
                        <div className={`col-4`}>
                            <div className={styles.goalsCard}>
                                <img src={goal1} alt=""></img>
                                <div className={styles.goalsSpan}>
                                    <span className={styles.goalsNormalText}>{t('body.goals.goal1.text1')}</span><span
                                    className={styles.goalsBoldText}>{t('body.goals.goal1.text2')} {'&'}{t('body.goals.goal1.text3')}</span>
                                    <span>{t('body.goals.goal1.text4')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={`col-4 ${styles.goalsSecondDiv}`}>
                            <div className={styles.goalsCard}>
                                <img src={goal2} alt=""></img>
                                <div className={styles.goalsSpan}>
                                    <span className={styles.goalsNormalText}>{t('body.goals.goal2.text1')} </span><span
                                    className={styles.goalsBoldText}>{t('body.goals.goal2.text2')}</span><span
                                    className={styles.goalsNormalText}>{t('body.goals.goal2.text3')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={`col-4`}>
                            <div className={styles.goalsCard}>
                                <img src={goal3} alt=""></img>
                                <div className={styles.goalsSpan}>
                                    <span className={styles.goalsNormalText}>{t('body.goals.goal3.text1')}</span><span
                                    className={styles.goalsBoldText}>{t('body.goals.goal3.text3')}<br></br>{t('body.goals.goal3.text4')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10">
                        <img src={sdg2} alt="" className={styles.sdgImage} style={{float: 'right'}}></img>
                        <img src={sdg1} alt="" className={styles.sdgImage} style={{float: 'right'}}></img>
                        <img src={sdgLogo} alt="" className={styles.sdgLogo} style={{float: 'right'}}></img>
                    </div>
                    {/*<div className="col-4">*/}
                    {/*   */}
                    {/*</div>*/}
                    {/*<div className="col-4">*/}
                    {/*    <div className={styles.sdgImages}>*/}
                    {/*        */}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>


                <div style={{height: '34em', paddingLeft: '9em', marginBottom: '3em'}}
                     className={`row ${styles.lifeStoryClass}`}>
                    <h2 className={styles.serviceHeading}>{t('body.aboutMe.myStory')}</h2>
                    <div className={`col-6`}>

                        <div className={styles.lifeStoryCardLeft}>
                            <p>
                                <span className={styles.lifeStoryBoldText}>{t('body.aboutMe.name')}</span>
                            </p>
                            <p className={styles.lifeStoryTitleText}><br></br>
                                {t('body.aboutMe.title')}
                            </p>

                            <p>
                                <span className={styles.lifeStoryNormalText}>{t('body.aboutMe.text1')}</span>
                            </p>
                            <br></br>
                            <p>
                                <span className={styles.lifeStoryNormalText}>{t('body.aboutMe.text2')}</span>
                            </p>
                            <br></br>
                            <p>
                                <span className={styles.lifeStoryNormalText}>{t('body.aboutMe.text3')}</span>
                            </p>
                        </div>
                    </div>
                    <div className={`col-6`}>
                        <img src={portret} alt="" className={styles.lifeCardImg}></img>
                    </div>
                </div>


                <div style={{height: '34em', paddingLeft: '9em', marginBottom: '6em'}}
                     className={`row ${styles.contactResp}`} id="contact">
                    <h2 className={styles.serviceHeading}>{t('body.contact.getInTouch')}</h2>
                    <div style={{marginTop: "3em"}}
                         className={`col-6 ${styles.bellowSixty}  ${styles.paddingBellowSixty}`}>

                        <NameForm></NameForm>

                    </div>
                    <div className="col-6">
                        <img src={graphic5} alt="" className={styles.formImg}></img>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BodyComponentMain
