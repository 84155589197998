import React from "react";
import HeaderComponent from "../components/headerComponent";
import FooterComponent from "../components/footerComponent";
import BodyComponentMain from "../components/bodyComponentMain";

const HomeView = () => {
    return (
        <>
            <HeaderComponent/>
            <BodyComponentMain/>
            <FooterComponent/>

        </>
    )
}

export default HomeView
