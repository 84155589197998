import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationEN from './locales/en/translations.json'
import translationDE from './locales/nl/translations.json';

const getStoredLanguage = () => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    return storedLanguage || 'gb';
};

const resources = {
    gb: {
        translation: translationEN
    },
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationDE
    }
};


i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
.init({
    lng: getStoredLanguage(),
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
    resources

});

export default i18n;





