import styles from "../css/homeView.module.scss";
import React, {useState} from "react";
import logoLinkedIn from "../assets/images/LI-In-Bug.png";
import logoIg from "../assets/images/Instagram@2x.png";
import {useTranslation} from "react-i18next";

const FooterComponent = () => {
    
   
    const { t } = useTranslation();
    const { i18n } = useTranslation();
   
   
    return (
        <>
            <footer className={styles.firstFooter}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h5 className={styles.theadText}>{t('footer.siteMap')}</h5>
                            <ul className={styles.tableText}>
                                <li><a href="/#home">{t('headers.home')}</a></li>
                                <li><a href="/#mission">{t('headers.mission')}</a></li>
                                <li><a href="/#service">{t('headers.service')}</a></li>
                                <li><a href="/#whyus">{t('headers.whyUs')}</a></li>
                                <li><a href="/#goals">{t('headers.goals')}</a></li>
                                <li><a href="/#contact">{t('headers.contact')}</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h5 className={styles.theadText}>{t('footer.legal')}</h5>
                            <ul className={styles.tableText}>
                                <li><a href="#">{t('footer.termsAndConditions')}</a></li>
                                <li><a href="#">{t('footer.privacyPolicy')}</a></li>
                                <li><a href="#">{t('footer.cookiePolicy')}</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h5 className={styles.theadText}>{t('footer.contactUs')}</h5>
                            <ul className={styles.tableText}>
                                <li>{t('footer.email')}: info@nicetogift.com</li>
                                <li>{t('footer.phone')}: +31 6 13225559</li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h5 className={styles.theadText}>{t('footer.followUs')}</h5>
                              <a href="https://www.linkedin.com/company/nice-to-gift/"><img src={logoLinkedIn} className={styles.socialIcons} alt="LinkedIn Icon"/></a>
                           <a href="https://www.instagram.com/nicetogift/"><img src={logoIg} className={styles.socialIcons} alt="Instagram Icon"/></a>

                        </div>
                    </div>
                </div>
            </footer>

            <footer className={styles.lastFooter}>
                <span className={`${styles.copyright} ${styles.respClassFooter}`}>&copy;{t('footer.copyright')}</span>
            </footer>
        </>
    )
}

export default FooterComponent
